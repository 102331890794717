
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { required, sameValue } from "@/helpers/rules";
import { userService } from "@/services/api/user.service";
import { ChangePassword } from "@/models/entities/change-password";
import { BreadCrumb, SnackbarModule } from "vuelando";

const authModule = getModule(AuthModule, store);
const overlayModule = getModule(OverlayModule, store);
const snackbarModule = getModule(SnackbarModule, store);

@Component
export default class ModifyPassword extends Vue {
  @Prop({ required: false, default: false, type: Boolean })
  private dialog: boolean;

  // STATE MAGAGEMENT
  private roleBoolean = true;
  private confirmNewPassword = "";
  private openDialog = false;
  private oldPassword = "";
  private resetPassword = false;
  private userEmail = "";
  private newPassword = "";

  private required = required;
  private sameValue = sameValue;

  private success = false;

  async mounted(): Promise<void> {
    if (this.dialog) this.openDialog = true;
    this.userEmail = authModule.userEmail;
  }

  private closeDialog() {
    this.openDialog = false;
    this.$emit("close-dialog");
  }
  private async ResetPassword() {
    overlayModule.showOverlay();
    await userService.ResetPassword();
    this.resetPassword = true;
  }

  private async checkPassword() {
    overlayModule.showOverlay();
    let confirmPassword = (await userService.CheckPassword(this.oldPassword))
      .data;
    if (confirmPassword) {
      this.openDialog = false;
    }
  }
  private async submitChange() {
    if (this.confirmNewPassword != this.oldPassword) {
      overlayModule.showOverlay();
      let changePassword: ChangePassword = {
        newPassword: this.newPassword,
        oldPassword: this.oldPassword,
      };
      await userService.ChangePassword(changePassword);
      this.success = true;
    } else {
      snackbarModule.showSnackbar({
        message: this.$tc("password.PasswordSame"),
        type: "error",
        timer: 5000,
      });
    }
  }

  private resetFields(): void {
    this.newPassword = "";
    this.confirmNewPassword = "";
    this.$emit("close-dialog");
  }
  private checkPassBtn(key: any): void {
    if (key.key == "Enter") {
      this.checkPassword();
    }
  }
}
