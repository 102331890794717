var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.openDialog)?_c('div',[_c('v-row',{staticClass:"pt-6 pb-4",attrs:{"align":"center"}},[_c('v-col',{staticClass:"z-heading-h4 text-on-background"},[_vm._v(_vm._s(_vm.$tc("password.TitleEdit"))+" ")])],1),(!_vm.success)?_c('div',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"z-body-regular-small text-on-background",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$tc("password.NewPW"))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('z-text-field',{attrs:{"errorMode":"icon","rules":[_vm.required],"removeHintSpace":"","type":"password","removeLabelSpace":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"z-body-regular-small text-on-background",attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(_vm.$tc("password.ConfirmNewPW"))+" ")]),_c('v-col',{attrs:{"cols":"8"}},[_c('z-text-field',{attrs:{"errorMode":"icon","type":"password","removeHintSpace":"","removeLabelSpace":"","rules":[
              _vm.required,
              _vm.sameValue(
                _vm.confirmNewPassword,
                _vm.newPassword,
                _vm.$tc('password.RequiredSamePW')
              ),
            ]},model:{value:(_vm.confirmNewPassword),callback:function ($$v) {_vm.confirmNewPassword=$$v},expression:"confirmNewPassword"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{staticStyle:{"max-width":"385px"},attrs:{"cols":"8","align":"right"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pr-4"},[_c('z-plain-button',{staticClass:"mr-4",attrs:{"type":"neutral"},on:{"click":_vm.resetFields}},[_vm._v(" "+_vm._s(_vm.$tc("password.Cancel"))+" ")])],1),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('z-basic-button',{attrs:{"type":"primary-color","icon":"check"},on:{"click":_vm.submitChange}},[_vm._v(" "+_vm._s(_vm.$tc("password.SavePassword"))+" ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.success)?_c('v-row',[_c('v-col',[_c('z-alert',{attrs:{"type":"success","message":_vm.$tc('password.ChangePassSuccess')}})],1)],1):_vm._e()],1):_vm._e(),_c('z-dialog',{attrs:{"persistent":"","maxWidth":"400px","title":_vm.$tc('password.InsertPassword'),"actions":[
      {
        slotText: _vm.$tc('password.Cancel'),
        plainType: 'primary',
        icon: '',
        action: () => _vm.closeDialog(),
      },
      {
        slotText: _vm.$tc('password.Confirm'),
        icon: 'check',
        type: 'primary-color',
        action: () => _vm.checkPassword(),
      },
    ]},on:{"undo":_vm.closeDialog},scopedSlots:_vm._u([{key:"fields",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('z-text-field',{attrs:{"type":"password","rules":[_vm.required],"width":"100%","label":_vm.$tc('password.Password')},on:{"keypress":_vm.checkPassBtn},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}})],1),_c('v-col',{staticClass:"pt-0 pb-6",attrs:{"cols":"12"}},[(!_vm.resetPassword)?_c('z-link',{on:{"click":function($event){return _vm.ResetPassword()}}},[_vm._v(" "+_vm._s(_vm.$tc("password.ForgotPW"))+" ")]):_vm._e(),(_vm.resetPassword)?_c('z-alert',{attrs:{"type":"primary","width":"352px","message":_vm.$tc('password.EmailSent') + _vm.userEmail,"dismissable":""}}):_vm._e()],1)],1)]},proxy:true}]),model:{value:(_vm.openDialog),callback:function ($$v) {_vm.openDialog=$$v},expression:"openDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }